import {
  Box,
  Textarea,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  Alert,
  AlertIcon,
  FormHelperText,
  FormErrorMessage,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { PinkButton } from '../components/buttons/pink';
import axios from 'axios';
import { getContactEndpoint } from '../api';

const submitForm = () => {
  const headers = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const handleReset = () => {
    document.getElementById('name').value = '';
    document.getElementById('email').value = '';
    document.getElementById('message').value = '';
  };

  const contactEndpoint = getContactEndpoint();

  axios
    .post(
      contactEndpoint,
      {
        Name: document.getElementById('name').value,
        Email: document.getElementById('email').value,
        Message: document.getElementById('message').value,
        Service: 'DFC',
      },
      headers
    )
    .then(res => {
      document.getElementById('success_alert').style.display = 'block';
      document.getElementById('error_alert').style.display = 'none';
      handleReset();
    })
    .catch(err => {
      console.error(err);
      document.getElementById('success_alert').style.display = 'none';
      document.getElementById('error_alert').style.display = 'block';
    });
};

export default function Contact() {
  //Setting State
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  //Disable button for empty fields
  const isInvalid = name === '' || email === '' || message === '';

  //Function calls for empty fields as a precaution
  const handleNameChange = e => setName(e.target.value);
  const isNameError = name === '';

  const handleEmailChange = e => setEmail(e.target.value);
  const isEmailError = email === '';

  const handleMessageChange = e => setMessage(e.target.value);
  const isMessageError = message === '';

  return (
    <Container
      maxW="lg"
      py={{ base: '12', md: '24' }}
      px={{ base: '0', sm: '8' }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
          <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
            <Heading
              color={'dfcone.500'}
              mb={2}
              fontSize={{ base: '3xl', md: '3xl' }}
              textAlign={'center'}
            >
              Contact Us
            </Heading>
          </Stack>
        </Stack>
        <Box
          py={{ base: '0', sm: '8' }}
          px={{ base: '4', sm: '10' }}
          bg={'white'}
          boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
          borderRadius={{ base: 'none', sm: 'xl' }}
        >
          <Stack spacing="6">
            <Stack spacing="6">
              <FormControl isRequired>
                <FormLabel htmlFor="name" color={'dfcblack.700'}>
                  Name
                </FormLabel>
                <Input
                  isInvalid={isNameError}
                  id="name"
                  type="text"
                  borderColor="dfctwo.500"
                  onChange={handleNameChange}
                />
                {!isNameError ? (
                  <FormHelperText color={'dfcblack.700'} textAlign={'left'}>
                    Enter your full name.
                  </FormHelperText>
                ) : (
                  <FormErrorMessage color="red">
                    Name is required.
                  </FormErrorMessage>
                )}
                <FormLabel htmlFor="email" color={'dfcblack.700'} mt={'4'}>
                  Email
                </FormLabel>
                <Input
                  isInvalid={isEmailError}
                  id="email"
                  type="email"
                  borderColor="dfctwo.500"
                  onChange={handleEmailChange}
                />
                {!isEmailError ? (
                  <FormHelperText color={'dfcblack.700'} textAlign={'left'}>
                    Enter the email you'd like to receive the reply to.
                  </FormHelperText>
                ) : (
                  <FormErrorMessage color={'red'}>
                    Email is required.
                  </FormErrorMessage>
                )}
                <FormLabel htmlFor="Message" color={'dfcblack.700'} mt={'4'}>
                  Message
                </FormLabel>
                <Textarea
                  isInvalid={isMessageError}
                  id="message"
                  borderColor="dfctwo.500"
                  onChange={handleMessageChange}
                />
                {!isMessageError ? (
                  <FormHelperText color={'dfcblack.700'} textAlign={'left'}>
                    Enter the message you wish to send to Downriver Foster
                    Closet.
                  </FormHelperText>
                ) : (
                  <FormErrorMessage color={'red'}>
                    Message is required to proceed.
                  </FormErrorMessage>
                )}
              </FormControl>
            </Stack>
            <Stack spacing="2">
              <PinkButton
                onSubmit
                px={6}
                mt={5}
                onClick={() => submitForm()}
                disabled={isInvalid}
              >
                Submit
              </PinkButton>
              <Text fontSize="xs" color="red" mt="-3">
                * You must fill in all information to submit!
              </Text>
              <Alert
                status="error"
                variant="top-accent"
                id="error_alert"
                style={{ display: 'none' }}
              >
                <AlertIcon />
                There was an error processing your request!
              </Alert>
              <Alert
                status="success"
                variant="top-accent"
                id="success_alert"
                style={{ display: 'none' }}
              >
                <AlertIcon />
                Your message was sent successfully!
              </Alert>
              <HStack>
                <hr color={'mkytwo.500'} />
                <Text
                  fontSize="lg"
                  whiteSpace="nowrap"
                  color="mkytwo.500"
                  textAlign={'left'}
                  pt="8"
                >
                  Downriver Foster Closet
                  <br />
                  12311 Reeck Rd
                  <br />
                  Southgate, Mi. 48195
                  <br />
                  734 288-3698
                </Text>
              </HStack>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}
