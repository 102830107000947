import {
  Box,
  Stack,
  Text,
  Link,
  Heading,
  useColorModeValue as mode
} from '@chakra-ui/react'
import * as React from 'react'


export default function Resources() {
  return(
    <Box as="section" bg={mode('gray.300', 'gray.800')} pt="16" pb="24">
      <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
        <Heading size="3xl" color="dfcfive.400">Resources</Heading>
        <Stack textAlign={'left'} spacing="1">
          <Heading Size="lg">Interested in Becoming a Foster Parent?</Heading>
          <Text>
            <Link
              href='https://www.michigan.gov/mdhhs/adult-child-serv/hopeforahome' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Michigan Foster Care
            </Link>
            {' '}-  In Michigan, there are approximately 14,000 children in foster care and 300 children who still need an adoptive family. Learn how you can help!
          </Text>
          <Text>
            <Link
              href='https://www.fcnp.org/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Foster Care Navigator
            </Link>
            {' '}- Whether you have been a foster parent for several years or you are just inquiring about becoming a foster parent, the benefits of having a Navigator are invaluable. 
          </Text>
          <Text>
            <Link
              href='https://joshshipp.com/oca-lp/?fbclid=IwAR39tXtdGci6-ZW6QMpFXDyBaxKfXij6MFLEdr33Ok0dwOqxM4u6D9YLY2w' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              The power of one caring adult
            </Link>
            {' '}- Every kid is one caring adult away from a Success story!
          </Text>
          <Text>
            <Link
              href='https://www.kansascity.com/news/special-reports/article238228079.html?fbclid=IwAR3-dYG_4RFLqf3ib35m6uYRx-457af2w2bsEf9haBH86KjbWJtWO5j4u7M' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Taken into foster care, through the eyes of a child
            </Link>
            {' '}- Before she was an inmate, Michelle Voorhees was a kid in foster care. Painting a vivid picture, Voorhees asks you to imagine the harrowing, disconcerting experience of being removed from your own home. 
          </Text>
          <Heading Size="lg" pt="25px">Interested in Adoption?</Heading>
          <Text>
            <Link
              href='https://www.mare.org/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Michigan Adoption Resource Exchange
            </Link>
            {' '}- M.A.R.E. is here to help you achieve your family’s adoption goals and meet some of the waiting children! 
          </Text>
          <Text>
            <Link
              href='https://miheart.org/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Michigan Heart Gallery
            </Link>
            {' '}- Help these youth find their forever homes!
          </Text>
          <Text>
            <Link
              href='https://www.michigan.gov/mdhhs/adult-child-serv/adoption' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Understanding Michigan's Adoption Program
            </Link>
            {' '}- Open your heart and your home!
          </Text>
          <Heading Size="lg" pt="25px">Help for Parents</Heading>
          <Text>
            <Link
              href='https://www.fosteringforwardmi.org/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Fostering Forward Michigan
            </Link>
            {' '}- Michigan's statewide foster, adoptive, and kinship parent coalition. Have a problem or a question? They can help!
          </Text>
          <Text>
            <Link
              href='https://www.fcnp.org/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Foster Care Navigator
            </Link>
            {' '}- Whether you have been a foster parent for several years or you are just inquiring about becoming a foster parent, the benefits of having a Navigator are invaluable.
          </Text>
          <Text>
            <Link
              href='https://drive.google.com/file/d/0B70qJXstg5pRMFd4enBvYVNNb3hoelZYVUg5TUFmLVBVaFBj/view?fbclid=IwAR2Sez9XJHklhJS3kZAML8_iKlcpye_7et9Xwde86nHrJG8_ln3cn1CsFWw' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Suggested Pre-Placement and Time-of-Placement Questions
            </Link>
            {' '}- a list of questions you may want to ask before agreeing to a placement from the Iowa Foster & Adoptive Parent Association.
          </Text>
          <Text>
            <Link
              href='https://www.michiganallianceforfamilies.org/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Michigan Alliance for Families
            </Link>
            {' '}- Support and education for special education services.
          </Text>
          <Text>
            <Link
              href='https://www.michigan.gov/fyit/Resources/youth-alum/michigan-youth-opportunities-initiative-myoi' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Michigan Youth Opportunity Initiative
            </Link>
            {' '}- Ensures that young people in foster care have successful outcomes in housing, education, employment, community engagement and health.
          </Text>
          <Text>
            <Link
              href='https://www.michigan.gov/mdhhs/keep-mi-healthy/mentalhealth/mentalhealth/cmhsp' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Statewide
            </Link>
            {' '}- Community Mental Health (CMH) Services
          </Text>
          <Text>
            <Link
              href='https://edmontonfetalalcoholnetwork.org/2021/02/19/confabulation-when-lying-isnt-lying-with-nate-sheets/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Confabulation
            </Link>
            {' '}- When lying isn't lying with Nate Sheets.
          </Text>
          <Text>
            <Link
              href='https://kinship.msu.edu/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Kinship Care Resource Center
            </Link>
            {' '}- Resources for family members raising a relative’s child.
          </Text>
          <Text>
            <Link
              href='https://www.drmich.org/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Disability Rights Michigan 
            </Link>
            {' '}- Representing the rights of children and adults with disabilities in Michigan. Download a free comprehensive advocacy manual!
          </Text>
          <Text>
            <Link
              href='https://www.mcfares.org/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              MCFARES
            </Link>
            {' '}- Michigan Coalition for Fetal Alcohol Resources, Education, and Support
          </Text>
          <Text>
            <Link
              href='http://fafasd.net/?fbclid=IwAR3uwbTJGjTW10Lm4QBlFRenDPL51i6ZnNN8YOfvpRyi1kMDZZ3vOlvAFrg' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              More resources
            </Link>
            {' '}- Families Affected by Fetal Alcohol Spectrum Disorder
          </Text>
          <Text>
            <Link
              href='http://www.fasdtoolkit.com/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              FASD toolkit
            </Link>
            {' '}- The FASD toolkit is a community generated media campaign about Fetal Alcohol Spectrum Disorders (FASD), created by and for the American Indian community.
          </Text>
          <Text>
            <Link
              href='https://www.fasdsuccess.com/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              FASD Success Show
            </Link>
            {' '}- Listen in to the latest episodes to get the expert tools, resources and support you need.
          </Text>
          <Text>
            <Link
              href='https://www.child-encyclopedia.com/pdf/expert/syndrome-dalcoolisation-foetale-saf/selon-experts/neurobehavioural-profiles-of-individuals-with' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              FASD for medical/counseling professionals
            </Link>
            {' '}- An easy handout to take to appointments.
          </Text>
          <Text>
            <Link
              href='https://drjohndegarmo.medium.com/5-things-you-need-to-survive-as-a-foster-parent-f3b76af6a9c0' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Good tips to remember!
            </Link>
            {' '}- Five Things You Need to Survive as a Foster Parent.
          </Text>
          <Text>
            <Link
              href='https://muslimfostercar' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Muslim Foster Care Associatio
            </Link>
            {' '}- Support for Muslim foster parents and Muslim children in foster care.
          </Text>
          <Text>
            <Link
              href='http://faithcommunitiescoalition.org/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Faith Communities Coalition on Foster Care
            </Link>
            {' '}- Faith communities in Michigan who engage in at least projects that benefit children and youth in foster care.
          </Text>
          <Text>
            <Link
              href='https://buildingbeds4kids.org/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Building Beds 4 Kids
            </Link>
            {' '}- Distributes beds and mattresses to foster families for free.
          </Text>
          <Text>
            <Link
              href='https://www.theneighborsproject.net/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              The Neighbors Project
            </Link>
            {' '}- Connecting neighbors who care with families who need support in Macomb, Oakland, and Wayne Counties.
          </Text>
          <Heading Size="lg" pt="25px">Michigan Training Opportunities for Parents</Heading>
          <Text>
            Foster, Adoptive, and Kinship Parent Conference - Free statewide must-attend conference that is held every year. To sign up for MDHHS training conference announcements, email Monica S. Jackson, Statewide Adoptive and Foster Parent Recruitment and Retention Coordinator, at
            <Link
              href='#' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              {' '} JacksonM9@michigan.gov
            </Link>
            .
          </Text>
          <Text>
            <Link
              href='https://www.michiganallianceforfamilies.org/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Michigan Alliance for Families
            </Link>
            {' '}- Support and education for special education services.
          </Text>
          <Heading Size="lg" pt="25px">Some of the Best Trauma-Informed Videos for Foster Parents</Heading>
          <Text>
            <Link
              href='#' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              The Post Institute
            </Link>
            {' '}- Every foster, adoptive, and kinship parent should watch these free videos:
            <Link
              href='https://www.youtube.com/watch?v=nGeCHUQLMYA' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              When You Feel Incompetent as a Parent!
            </Link>
            {'  '}
            <Link
              href='https://www.youtube.com/watch?v=rHlJEr4ebM0&t=269s' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Why Kids Lie and How to Stop It Now!
            </Link>
            {'  '}
            <Link
              href='https://www.youtube.com/watch?v=x5Xw75lv3t8' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Six Minutes that Can Change Your Child's Life!
            </Link>
            {'  '}
            <Link
              href='https://www.youtube.com/watch?v=jYyEEMlMMb0' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Trauma, Brain & Relationship: Helping Children Heal
            </Link>
            {'  '}
            <Link
              href='https://www.youtube.com/user/postinstitute/videos' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              More videos
            </Link>
          </Text>
          <Text>
            <Link
              href='#' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Cognitive Supports
            </Link>
            {' '} (formerly Oregon Behavior Consultants) -  Specialization in Fetal Alcohol Spectrum Disorders (FASDs), developmental disabilities, and cognitive skills. {' '}
            <Link
              href='https://www.youtube.com/c/oregonbehavior' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Free videos that all foster, adoptive, and kinship parents should watch.
            </Link>
          </Text>
          <Text>
            <Link
              href='#' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Children in Crisis: Trauma and Toxic Environments Impact How Children Learn
            </Link>
            {' '}- Former Free Press Columnist Rochelle Riley studied how trauma and toxic environments impact how children learn. 
          </Text>
          <Heading Size="lg" pt="25px">Facebook Support and Resource Pages</Heading>
          <Text>
            <Link
              href='https://www.facebook.com/fostercare/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Foster Care and Adoption
            </Link>
            {' '}- Nationwide community of foster and adoptive parents.
          </Text>
          <Text>
            <Link
              href='https://www.facebook.com/groups/mifasd/about/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              MI FASD
            </Link>
            {' '}- Parents, clinicians, advocates and services for Fetal Alcohol Spectrum Disorder.
          </Text>
          <Heading Size="lg" pt="25px">Holiday and Other Stuff for Your Kids</Heading>
          <Text>
            <Link
              href='https://bigfamilyofmi.org/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Big Family of Michigan
            </Link>
            {' '}- Support for foster families during the holidays and more.
          </Text>
          <Text>
            <Link
              href='https://friendsoffosterkids.org/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Friends of Foster Kids
            </Link>
            {' '}- Free Christmas gifts. Requests must come directly from your DHHS caseworker between August and October. If licensed with a private agency, have your caseworker contact your child's assigned DHHS monitor to apply for your child.
          </Text>
          <Text>
            <Link
              href='https://themayflyproject.com/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              The MayFly Project
            </Link>
            {' '}- Mentoring Children in Foster Care Through Fly Fishing.
          </Text>
          <Text>
            <Link
              href='https://www.marieantoinetteprogram.com/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Marie Antoinette Program
            </Link>
            {' '}- "Let Them Eat Cake"  assists struggling families by giving Michigan kids something special just for them.
          </Text>
          <Text>
            <Link
              href='https://renewc.org/toys4kids?fbclid=IwAR3vzdhiZG0leZdN3WqqV0XXwEAuKCntAT8catcByoKXfBMLA63DMlWO7cU' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Renew Charities 
            </Link>
            {' '}- For families with low income, we provide family dinners and toys to help with Christmas.
          </Text>
          <Text>
            <Link
              href='https://www.kidsbowlfree.com/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Kids Bowl Free 
            </Link>
            {' '}- Sign up for two free games of bowling each day all summer long!
          </Text>
          <Text>
            <Link
              href='https://kidsskatefree.com/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Kids Skate Free
            </Link>
            {' '}- Find a participating skating center near you or ask them to sign up!
          </Text>
          <Heading Size="lg" pt="25px">Michigan Summer Camps</Heading>
          <Text>ASK! Many camps give scholarships or discounts to children in foster care.</Text>
          <Text>
            <Link
              href='https://www.careofsem.com/events/summer-camps/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              CARES of Southeast Michigan
            </Link>
            {' '}- Free summer day camps focused on leadership skills.
          </Text>
          <Text>
            <Link
              href='http://mychamplife.com/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Champions for Life
            </Link>
            {' '}- Free, religious-based. Referrals can also be made through Macomb County DHHS.
          </Text>
          <Text>
            <Link
              href='https://svdpdetroit.org/How-We-Help/Camps' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Camp Ozanam
            </Link>
            {' '}- Free, religious-based. Campers do not have to be Catholic to attend our camp but they do need to go through their local Catholic Church that has a St. Vincent de Paul (SVdP) Conference. You can also call St. Vincent de Paul at 1-877-788-4623, #2, #5.
          </Text>
          <Text>
            <Link
              href='https://www.forthechildren.org/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Royal Family Kids Camp
            </Link>
            {' '}- Free, religious-based. Worker referral needed. 
          </Text>
          <Text>
            <Link
              href='https://www.michiganymcacamps.org' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Michigan YMCA Camps
            </Link>
            {' '}- Scholarships available.
          </Text>
          <Text>
            <Link
              href='http://camphenry.org/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Camp Henry
            </Link>
            {' '}- Scholarships available.
          </Text>
          <Text>
            <Link
              href='https://www.campskyline.org/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Camp Skyline
            </Link>
            {' '}- Scholarships available.
          </Text>
          <Text>
            <Link
              href='https://loveforachild.org/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Love for a Child
            </Link>
            {' '}- Free, religious-based.
          </Text>
          <Text>
            <Link
              href='https://www.youthhaven.org/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Youth Haven
            </Link>
            {' '}- Free, religious-based.
          </Text>
          <Text>
            <Link
              href='https://www.springhillexperiences.com/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Camp Springhill
            </Link>
            {' '}- Religious-based, scholarships available.
          </Text>
          <Text>
            <Link
              href='https://ikuslife.org/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Indian Trails Camp
            </Link>
            {' '}- Specializing in helping children with disabilities.
          </Text>
          <Text>
            Community Enrichment and Sports Scholarships - ASK! Most organizations provide scholarships and discounts for children in foster care. The agency you are licensed with may have funds available as well.
          </Text>
          <Heading Size="lg" pt="25px">College and Other Teen Resources</Heading>
          <Text>
            <Link
              href='https://comfortcases.org/scholarship/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Comfort Case Scholarships
            </Link>
            {' '}- Awarding scholarships to support the academic and vocational goals of youth in foster care.
          </Text>
          <Text>
            <Link
              href='http://fosteringsuccessmichigan.com/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Fostering Success Michigan
            </Link>
            {' '}- Provides comprehensive resources and inspiration for youth and alumni of foster care.
          </Text>
          <Text>
            <Link
              href='https://www.michigan.gov/fyit/Resources/youth-alum/michigan-youth-opportunities-initiative-myoi' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Michigan Youth Opportunity Initiative
            </Link>
            {' '}- Ensures that young people in foster care have successful outcomes in housing, education, employment, community engagement, and health. This is a great opportunity for your teen to earn money just by participating!
          </Text>
          <Text>
            <Link
              href='https://www.michigan.gov/-/media/Project/Websites/fyit/Site-Documents/DHS-Pub-0089.pdf?' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Foster Youth in Transition
            </Link>
            {' '}- A handbook for youth in foster care.
          </Text>
          <Text>
            <Link
              href='https://www.michigan.gov/-/media/Project/Websites/leo/Documents/MRS1/mcticatalog08.pdf?' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Michigan Career and Technical Institute
            </Link>
            {' '}- MCTI is one of the most progressive public vocational rehabilitation residential training facilities in the U.S., with extraordinary training and job placement success. Let them help your teen get on the path to success.
          </Text>
          <Text>
            <Link
              href='https://www.michigan.gov/setwithmet/scholarship/charitable' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Fostering Futures Scholarship
            </Link>
            {' '}- Provides college assistance for former foster youth.
          </Text>
          <Text>
            <Link
              href='https://www.michigan.gov/mistudentaid/programs/tuition-incentive-program' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Tuition Incentive Program (TIP)
            </Link>
            {' '}- Any child receiving Medicaid for a period of time qualifies.
          </Text>
          <Text>
            <Link
              href='https://champs.wayne.edu/#:~:text=CHampions%20Aspiring%20to%20Make%20Pathways,are%20enrolled%20students%20at%20Wayne' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Wayne State University CHAMPS
            </Link>
            {' '}- A  college access and retention program for youth ages 18- 26 who have "aged out" of the child welfare system.
          </Text>
          <Text>
            <Link
              href='https://www.fastweb.com/financial-aid/articles/financial-aid-and-scholarships-for-foster-care-and-adopted-children' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              PELL Scholarship and other Scholarships 
            </Link>
            {' '}- Youth who aged out of foster care or were adopted out of foster care after reaching age 13 are considered automatically independent on the Free Application for Federal Student Aid (FAFSA). Often this means that such children have a zero expected family contribution (EFC), which qualifies them for a full Pell Grant. 
          </Text>
          <Text>
            <Link
              href='https://www.childwelfare.gov/topics/adoption/adopt-people/assistance/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Child Welfare Information Gateway
            </Link>
            {' '}- Government information on educational assistance.
          </Text>
          <Heading Size="lg" pt="25px">Adoptive Family Resources</Heading>
          <Text>
            <Link
              href='https://www.postadoptionrc.org/' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Post Adoption Resource Center
            </Link>
            {' '}- Providing support and resources every step of the way.
          </Text>
          <Text>
            <Link
              href='https://www.mare.org/For-Families/Post-Adoption-Information' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Post Adoption Toolkit
            </Link>
            {' '}- Resources compiled by MARE.
          </Text>
          <Text>
            <Link
              href='https://dabsj.org/what-we-do/adoption/post-adoption-support' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Adoptive Family Support Network
            </Link>
            {' '}- Statewide parent to parent program providing post-adoption support, education and resources to families across Michigan.
          </Text>
          <Text>
            <Link
              href='https://www.michigan.gov/mdhhs/adult-child-serv/adoption/post-adoption-resources/post-adopt-assist' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              State of Michigan Adoption Subsidy Office
            </Link>
            {' '}- Financial resources after adoption.
          </Text>
          <Heading Size="lg" pt="25px">Report Child Abuse and Neglect</Heading> 
          <Text>
            <Link
              href='#' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              Statewide Hotline
            </Link>
            {' '}- Report Abuse & Neglect
          </Text>
          <Heading Size="lg" pt="25px">Serving the foster community and not listed? Let us know!</Heading> 
          <Text>
            Email us your details 
            <Link
              href='/contact' 
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
              color="dfcone.500"
            >
              {' '} HERE!
            </Link>
            .
          </Text>
        </Stack>
      </Box>
    </Box>
  )
}