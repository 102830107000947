import {
  Box,
  Divider,
  Flex,
  Stack,
  Text,
  Link,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import Logo from '../logo/logo'
import { PurpleButton } from '../buttons/purple'
import { BlueButton } from '../buttons/blue'
import { BeatingHeart } from 'beating-heart-emoji'
import 'beating-heart-emoji/dist/index.css'

const packageJson = require('../../../package.json');

export default function Footer() {
  return(
    <Box 
      bg={mode('gray.200', 'gray.800')} 
      pt="2" 
      pb="5" 
      as="footer" 
      w="100%"
    >
      <Box 
        maxW={{ 
          base: 'xl', 
          md: '7xl' 
        }} 
        mx="auto" 
        px={{ 
          base: '6', 
          md: '8'
         }}
      >
        <Stack
          spacing="8"
          direction={{
            base: 'column',
            md: 'row',
          }}
          justify="space-between"
          py={{
            base: '12',
            md: '16',
          }}
        >
          <Stack
            spacing={{
              base: '6',
              md: '8',
            }}
            align="start"
          >
            <Logo />
            <Text 
              color="muted" 
              align="left"
            >
              Please make checks payable to The Downriver Foster Closet.
            </Text>
            <Text 
              align="left"
            >
              Mail To:<br />
              Downriver Foster Closet <br />
              1753 Ford Ave. <br />
              Wyandotte, MI 48192 <br /> 
            </Text>
          </Stack>
          <Stack
            direction={{
              base: 'column-reverse',
              md: 'column',
              lg: 'row',
            }}
            spacing={{
              base: '12',
              md: '8',
            }}
          >
            <Stack direction="row" spacing="8">
              <Stack spacing="4" minW="36" flex="1">
                <Text fontSize="lg" fontWeight="semibold" color="subtle">
                  Donate
                </Text>
                <Link 
                  href="https://www.facebook.com/Downriverfostercloset" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }} 
                  isExternal
                >
                  <BlueButton>
                    Facebook
                  </BlueButton>
                </Link>
                </Stack>
                <Stack spacing="4" minW="36" flex="1">
                  <Text fontSize="lg" fontWeight="semibold" color="subtle">
                    Volunteer
                  </Text>
                  <Link 
                    href='https://docs.google.com/forms/d/e/1FAIpQLSc0hhWyU6FJhJy3waVdad8TOhp0ZJceyy5Ib9zcFeMQ22I_qQ/viewform?fbclid=IwAR3oU31AbP-ii2VuPnVbGaDc1YY62AvP_UDt_bZyK7SYlOTZHsUDuGtE6sc' 
                    isExternal
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <PurpleButton>
                      Volunteer
                    </PurpleButton>
                  </Link>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          </Box>
        <Divider />
        <Stack
          w="100%"
          pt="4"
          pb="2"
          pl="5"
          pr="5"
          justify="space-between"
          direction={{
            base: 'column-reverse',
            md: 'row',
          }}
          align="center"
        >
          <Text fontSize="md" color="subtle">
            &copy; {new Date().getFullYear()} 
              Downriver Foster Closet
              <Link
                href='https://dgxn14gd1tpd4.cloudfront.net/' 
                isExternal
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <small>
                  {'  '}v{packageJson.version}
                </small>
              </Link>
            </Text>
          <Flex
            direction={'row'}
            spacing={6}
          >
            <Text 
              fontSize="xs" 
              color="subtle"
            >
              Built with 
              <BeatingHeart /> 
              by 
              <Link
                href='https://www.pingsoftwareusa.com/home' 
                isExternal
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
              {' '} Ping Software USA
              </Link>
            </Text>
          </Flex>
        </Stack>
    </Box>
)
    }
